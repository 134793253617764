import React from "react";
import "./Home.css";
import { useTranslation } from "react-i18next";

export const Home = () => {
  const { t } = useTranslation();
  return (
    <div className="home-container">
      <div className="company-section">
        <h2 className="company-title">{t("ONas")}</h2>
        <p className="company-description">{t("OpisFirmy")}</p>
      </div>

      <div className="products-container">
        <div className="product-section stairs">
          <img
            src="./home-stairs.jpg"
            alt="wooden stairs"
            className="product-image"
          />
          <p className="product-description">{t("OpisSchodów")}</p>
          <a
            href="https://www.facebook.com/media/set/?set=a.107812863970769&type=3"
            className="product-link"
          >
            {t("GaleriaSchodow")}
          </a>
        </div>

        <div className="product-section fences">
          <img
            src="./fence-home.jpg"
            alt="Płoty drewniane"
            className="product-image"
          />
          <p className="product-description">{t("OpisPlotow")}</p>
          <a
            href="https://www.facebook.com/media/set/?set=a.107801990638523&type=3"
            className="product-link"
          >
            {t("GaleriaPlotow")}
          </a>
        </div>
      </div>
    </div>
  );
};

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(HttpBackend) // ładuje tłumaczenia z serwera
  .use(LanguageDetector) // wykrywa język użytkownika
  .use(initReactI18next) // inicjalizuje react-i18next
  .init({
    fallbackLng: "de", // język domyślny
    debug: true, // ustaw na false w produkcji
  });

export default i18n;

import { Route, Routes } from "react-router-dom";
import "./App.css";
import { Navbar } from "./components/Navbar";
import { Footer } from "./components/Footer";
import { Treppe, Zaune, Kontakt, Home } from "./components/pages";
import FenceForm from "./components/FenceForm";
import StairsForm from "./components/StairsForm";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/treppe" element={<Treppe />} />
        <Route path="/zaune" element={<Zaune />} />
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="/fenceForm" element={<FenceForm />} />
        <Route path="/stairsForm" element={<StairsForm />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Navbar.css";
import { useTranslation } from "react-i18next";

export const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  return (
    <div className="nav-background">
      <nav>
        <Link to="/" className="title">
          <img src="../Stolwit-logo-cut.png" alt="logo" />
        </Link>
        <div className="lang-buttons">
          <button
            className="lang-button-de"
            onClick={() => i18n.changeLanguage("de")}
          ></button>
          <button
            className="lang-button-pl"
            onClick={() => i18n.changeLanguage("pl")}
          ></button>
        </div>
        <div
          className="menu"
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>

        <ul className={menuOpen ? "open" : "nav-btns"}>
          <li>
            <NavLink to="/treppe">{t("NavSchody")}</NavLink>
          </li>
          <li>
            <NavLink to="/zaune">{t("NavPloty")}</NavLink>
          </li>
          <li>
            <NavLink to="/kontakt">Kontakt</NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

import React from "react";
import { Link } from "react-router-dom";
import "./Kontakt.css";
import { useTranslation } from "react-i18next";

export const Kontakt = () => {
  const { t } = useTranslation();
  return (
    <div className="kontakt-background">
      <div className="container">
        <h3>{t("SkontaktujSie")}</h3>
        <div className="contact-info">
          <div>
            Email:
            <a href="mailto:biuro@stolwit.net"> biuro@stolwit.net</a>
          </div>
          <div>
            Facebook:
            <a href="https://www.facebook.com/StolwitTreppe/"> Stolwit</a>
          </div>
          <div>
            Tel/WhatsApp: <br />
            DE: <a href="tel:015-224828058">015-224828058</a>
            <br />
            PL: <a href="tel:500-110-790">500-110-790</a>
          </div>
        </div>
        <h3>{t("WypelnijFormularz")}</h3>
        <div className="form-links">
          <Link to="/fenceForm">{t("WycenaPlotu")}</Link>
          <Link to="/stairsForm">{t("WycenaSchodow")}</Link>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import "./Zaune.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const Zaune = () => {
  const { t } = useTranslation();
  return (
    <div className="image-with-text-container">
      <div className="image-container">
        <img src="./fence-photo.jpg" alt="metal fence" />
        <a href="https://www.facebook.com/media/set/?set=a.107801990638523&type=3">
          {t("GaleriaPlotow")}
        </a>
      </div>
      <div className="text-container">
        <p>{t("TekstPlotow")}</p>
        <Link to="/kontakt" className="kontakt-button">
          {t("ButtonDoKontaktu")}
        </Link>
      </div>
    </div>
  );
};

import React from "react";
import "./Treppe.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const Treppe = () => {
  const { t } = useTranslation();
  return (
    <div className="image-with-text-container">
      <div className="image-container">
        <img src="./stairs-photo.jpg" alt="wooden stairs" />
        <a href="https://www.facebook.com/media/set/?set=a.107812863970769&type=3">
          {t("GaleriaSchodow")}
        </a>
      </div>
      <div className="text-container">
        <p>{t("TekstSchodow")}</p>
        <Link to="/kontakt" className="kontakt-button">
          {t("ButtonDoKontaktu")}
        </Link>
      </div>
    </div>
  );
};

import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./StairsForm.css";
import { useTranslation } from "react-i18next";

const stairsOptions = {
  stairsType: [
    { name: "Proste", image: null },
    { name: "1/2", image: null },
    { name: "1/4", image: null },
  ],
  riser: [
    { name: "ZPodstopniem", image: null },
    { name: "Otwarte", image: null },
  ],
  woodType: [
    { name: "buk", image: null },
    { name: "dab", image: null },
    { name: "jesion", image: null },
    { name: "inne", image: null },
  ],
  painting: [
    { name: "lakierBezbarwny", image: null },
    { name: "kolor", image: null },
  ],
  balustradeType: [
    { name: "drewniana", image: null },
    { name: "stalNierdzewna", image: null },
    { name: "inne", image: null },
  ],
};

const OptionTree = ({
  title,
  options,
  onSelect,
  selectedOption,
  t,
  selections,
  handleInputChange,
}) => {
  return (
    <div className="option-tree-container">
      <h3>{t(title)}</h3>
      <div className="option-tree-buttons">
        {options.map((option, index) => (
          <button
            key={index}
            type="button"
            onClick={() => onSelect(title, option.name)}
            className={selectedOption === option.name ? "selected" : ""}
          >
            {t(option.name)}
          </button>
        ))}
      </div>
      {selectedOption === "inne" && (
        <div className="input-container" style={{ marginTop: "10px" }}>
          <input
            name={
              title === "woodType"
                ? "customWoodType"
                : title === "balustradeType"
                  ? "customBalustradeType"
                  : ""
            }
            type="text"
            placeholder={t(`WpiszSwoj`)}
            value={
              title === "woodType"
                ? selections.customWoodType
                : title === "balustradeType"
                  ? selections.customBalustradeType
                  : ""
            }
            onChange={handleInputChange}
          />
        </div>
      )}
    </div>
  );
};

const StairsForm = () => {
  const { t } = useTranslation();
  const [selections, setSelections] = useState({
    stairsType: "",
    riser: "",
    woodType: "",
    customWoodType: "",
    notes: "",
    painting: "",
    zipCode: "",
    email: "",
    phoneNumber: "",
    deadline: "",
    balustradeType: "",
    customBalustradeType: "",
  });

  const [submitted, setSubmitted] = useState(false);

  const handleSelect = (type, optionName) => {
    setSelections((prevSelections) => ({
      ...prevSelections,
      [type]: optionName,
      ...(type === "woodType" &&
        optionName !== "inne" && { customWoodType: "" }),
      ...(type === "balustradeType" &&
        optionName !== "inne" && { customBalustradeType: "" }),
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelections({ ...selections, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (submitted) return;

    const emailData = {
      ...selections,
      message: `Wybrane opcje: ${JSON.stringify(selections, null, 2)}`,
    };

    setSubmitted(true);

    emailjs
      .send(
        "service_c5ts2dl",
        "template_lguoo0o",
        emailData,
        "b2hgrb2zGFlpupfJT"
      )
      .then(
        (response) => {
          console.log("Email successfully sent!", response);
          setSelections({
            stairsType: "",
            riser: "",
            woodType: "",
            customWoodType: "",
            notes: "",
            painting: "",
            zipCode: "",
            email: "",
            phoneNumber: "",
            deadline: "",
            balustradeType: "",
            customBalustradeType: "",
          });
          setTimeout(() => setSubmitted(false), 5000);
        },
        (err) => {
          console.log("Failed to send email, error: ", err);
          setSubmitted(false);
        }
      );
  };

  return (
    <div className="form-bg">
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          {Object.keys(stairsOptions).map((optionKey) => (
            <OptionTree
              key={optionKey}
              title={optionKey}
              options={stairsOptions[optionKey]}
              onSelect={handleSelect}
              selectedOption={selections[optionKey]}
              t={t}
              selections={selections}
              handleInputChange={handleInputChange}
            />
          ))}
          <h3>{t("E-mail")}</h3>
          <input
            name="email"
            type="email"
            placeholder={t("Twój e-mail")}
            value={selections.email}
            onChange={handleInputChange}
          />
          <h3>{t("Telefon")}</h3>
          <input
            name="phoneNumber"
            type="tel"
            placeholder={t("Twój numer telefonu (np. 123-456-7890)")}
            value={selections.phoneNumber}
            onChange={handleInputChange}
          />
          <h3>{t("Oczekiwany termin wykonania")}</h3>
          <input
            name="deadline"
            type="text"
            placeholder={t("Podaj termin wykonania")}
            value={selections.deadline}
            onChange={handleInputChange}
          />
          <h3>{t("Kod pocztowy")}</h3>
          <input
            name="zipCode"
            type="text"
            placeholder={t("Kod pocztowy XX-XXX")}
            value={selections.zipCode}
            onChange={handleInputChange}
          />
          <h3>{t("Dodatkowe informacje")}</h3>
          <textarea
            name="notes"
            placeholder={t("Dodaj uwagi")}
            value={selections.notes}
            onChange={handleInputChange}
          />
          <button type="submit">{t("Wyślij wybrane opcje")}</button>
          {submitted && (
            <p className="success-message">
              {t("Dziękujemy za wysłanie zapytania!")}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default StairsForm;

import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./FenceForm.css";
import { useTranslation } from "react-i18next";

const stairsOptions = {
  gate: [
    { name: "ZBrama", image: null },
    { name: "BezBramy", image: null },
  ],
  wicket: [
    { name: "ZFurtka", image: null },
    { name: "BezFurtki", image: null },
  ],
  painting: [
    { name: "Ocynk", image: null },
    { name: "OcynkKolor", image: null },
  ],
  motor: [
    { name: "ZNapedem", image: null },
    { name: "BezNapedu", image: null },
  ],
};

const OptionTree = ({ title, options, onSelect, selectedOption }) => {
  const { t } = useTranslation();
  const handleOptionClick = (optionName) => {
    if (selectedOption === optionName) {
      onSelect(null);
    } else {
      onSelect(optionName);
    }
  };

  return (
    <div className="option-tree-container">
      <h3>{title}</h3>
      <div className="option-tree-buttons">
        {options.map((option, index) => (
          <button
            key={index}
            type="button" // Dodano atrybut type="button"
            onClick={() => handleOptionClick(option.name)}
            className={selectedOption === option.name ? "selected" : ""}
          >
            {t(option.name)}
          </button>
        ))}
      </div>
    </div>
  );
};

const FenceForm = () => {
  const { t } = useTranslation();
  const initialState = {
    dimensions: "",
    gate: null,
    wicket: null,
    painting: null,
    email: "",
    notes: "",
    phoneNumber: "",
    motor: null,
    zipCode: "",
    deadline: "",
    project: "",
  };
  const [selections, setSelections] = useState(initialState);
  const [submitted, setSubmitted] = useState(false);

  const handleSelect = (type, optionName) => {
    setSelections((prevSelections) => ({
      ...prevSelections,
      [type]: optionName,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelections({ ...selections, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (submitted) return; // Blokada wysyłania, jeśli już wysłano

    const emailData = {
      ...selections,
      message: `Wybrane opcje: ${JSON.stringify(selections, null, 2)}`,
    };

    emailjs
      .send(
        "service_c5ts2dl",
        "template_lguoo0o",
        emailData,
        "b2hgrb2zGFlpupfJT"
      )
      .then(
        (response) => {
          console.log("Email successfully sent!", response);
          setSelections(initialState); // Resetowanie stanu formularza
          setSubmitted(true);
          setTimeout(() => setSubmitted(false), 5000); // Reset submitted stanu po 5 sekundach
        },
        (err) => {
          console.log("Failed to send email, error: ", err);
        }
      );
  };

  return (
    <div className="form-bg">
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <h3>{t("Wymiary")}</h3>
          <input
            name="dimensions"
            type="text"
            placeholder={t("WymiaryPlaceholder")}
            value={selections.dimensions}
            onChange={handleInputChange}
          />
          {/* Opcje dla bramy, furtki, malowania, i napędu */}
          <OptionTree
            title={t("Brama")}
            options={stairsOptions.gate}
            onSelect={(optionName) => handleSelect("gate", optionName)}
            selectedOption={selections.gate}
          />
          <OptionTree
            title={t("Wicket")}
            options={stairsOptions.wicket}
            onSelect={(optionName) => handleSelect("wicket", optionName)}
            selectedOption={selections.wicket}
          />
          <OptionTree
            title={t("Painting")}
            options={stairsOptions.painting}
            onSelect={(optionName) => handleSelect("painting", optionName)}
            selectedOption={selections.painting}
          />
          <OptionTree
            title={t("Motor")}
            options={stairsOptions.motor}
            onSelect={(optionName) => handleSelect("motor", optionName)}
            selectedOption={selections.motor}
          />
          {/* Pola e-mail, telefon, itd. */}
          <h3>{t("E-mail")}</h3>
          <input
            name="email"
            type="email"
            placeholder={t("Twój e-mail")}
            value={selections.email}
            onChange={handleInputChange}
          />
          <h3>{t("Telefon")}</h3>
          <input
            name="phoneNumber"
            type="tel"
            placeholder={t("Twój numer telefonu (np. 123-456-7890)")}
            value={selections.phoneNumber}
            onChange={handleInputChange}
          />
          <h3>{t("Oczekiwany termin wykonania")}</h3>
          <input
            name="deadline"
            type="text"
            placeholder={t("Podaj termin wykonania")}
            value={selections.deadline}
            onChange={handleInputChange}
          />
          <h3>{t("Kod pocztowy")}</h3>
          <input
            name="zipCode"
            type="text"
            placeholder={t("Kod pocztowy XX-XXX")}
            value={selections.zipCode}
            onChange={handleInputChange}
          />
          <h3>{t("Wzór")}</h3>
          <input
            name="project"
            type="text"
            placeholder={t("Numer wzoru")}
            value={selections.project}
            onChange={handleInputChange}
          />
          <h3>{t("Dodatkowe informacje")}</h3>
          <textarea
            name="notes"
            placeholder={t("Dodaj uwagi")}
            value={selections.notes}
            onChange={handleInputChange}
          />
          <button type="submit" disabled={submitted}>
            {t("Wyślij wybrane opcje")}
          </button>
          {submitted && (
            <p className="success-message">
              {t("Dziękujemy za wysłanie zapytania!")}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default FenceForm;

import React from "react";
import "./Footer.css";

export const Footer = () => {
  return (
    <div className="footer-bg">
      <address className="contact">
        <div className="footer-info">
          Email:
          <a className="footer-link" href="mailto:biuro@stolwit.net">
            {" "}
            biuro@stolwit.net
          </a>
          <div className="footer-info">
            Facebook:
            <a
              className="footer-link"
              href="https://www.facebook.com/StolwitTreppe/"
            >
              {" "}
              Stolwit
            </a>
          </div>
        </div>

        <div className="footer-info">
          Kontakt: <br />
          DE:{" "}
          <a className="footer-link" href="tel:015-224828058">
            015-224828058
          </a>
          <br />
          PL:{" "}
          <a className="footer-link" href="tel:500-110-790">
            +48 500-110-790
          </a>
        </div>
      </address>
    </div>
  );
};
